import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
import { initRem } from './assets/js/function'
import "vant/lib/index.css"
import { Dialog } from 'vant';
import "./assets/img/icon"
import SvgIcon from '@/components/SvgIcon'// svg组件

// import VConsole from 'vconsole';

// const vConsole = new VConsole();

// register globally
createApp().use(SvgIcon)

initRem(window, document, false)

createApp(App)
.use(router)
.use(Dialog)
.component('svg-icon', SvgIcon)
.directive('down-loader',{   //滚动到底部事件指令
    mounted(el, binding) {
        const SELECTWRAP_DOM = el;
        SELECTWRAP_DOM.addEventListener('scroll', function() {
            const condition =
            SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
            if (condition) {
                binding.value(SELECTWRAP_DOM.scrollTop);
            }
        });
    }
})
.directive('scroll-top', {
    mounted(el, binding) {
        const SELECTWRAP_DOM = el;
        SELECTWRAP_DOM.addEventListener('scroll', function() {
            binding.value(SELECTWRAP_DOM.scrollTop);
        });
    }
})
.mount('#app')
