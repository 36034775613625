<template>
    <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
            <van-loading color="#CCC" size="10rem" />
        </div>
    </van-overlay>
</template>
<script>
import { Overlay, Loading } from 'vant';
export default {
    components:{
        vanOverlay:Overlay,
        vanLoading:Loading
    },
    data(){
        return{
            show:true
        }
    }
}
</script>
<style scoped>
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .van-overlay{
        z-index: 9999;
    }
</style>