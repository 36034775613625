// import Home from '../pages/home.vue'
// import MerchantMap from '../pages/merchant_map.vue'
// import Share from '../pages/share.vue'
// import WelfareDetails from '../pages/welfare_details.vue'
// import WelfareText from '../pages/welfare_text.vue'
// import ShareList from '../pages/shareList.vue'
// import Merchant from '../pages/merchant.vue'
// import MerchantDetails from '../pages/merchant_details.vue'
// import User from '../pages/user.vue'
// import DiscountDetails from "../pages/user_discount_details.vue"
// import Welfare from "../pages/welfare.vue"
// import welfareClass from "../pages/welfare_class.vue"
import * as VueRouter from 'vue-router'
import { getShareInfo, setAuthRedirectUrl } from '@/assets/js/function'
import wx from 'weixin-js-sdk'
const routes = [
    { path:"/Home", component: () => import('@/pages/home.vue'), alias: '/Home', name:"Home", meta:{keepAlive:true}},
    { path: '/MerchantMap', component:  () => import('@/pages/merchant_map.vue'),name:"MerchantMap", meta:{keepAlive:true}},
    { path: '/Share/:id/:userId', component:  () => import('@/pages/share.vue'),name:"Share"},
    { path: '/Welfare/:type/:bg', component:  () => import('@/pages/welfare.vue'),name:"Welfare", meta:{keepAlive:true}},
    { path: '/WelfareClass', component:  () => import('@/pages/welfare_class.vue'),name:"WelfareClass", meta:{keepAlive:true}},
    { path: '/WelfareDetails/:id', component:  () => import('@/pages/welfare_details.vue'),name:"WelfareDetails"},
    { path: '/WelfareText/:id', component:  () => import('@/pages/welfare_text.vue'),name:"WelfareText"},
    { path: '/ShareList/:id/:userId', component:  () => import('@/pages/shareList.vue'),name:"ShareList"},
    { path: '/Merchant/:id', component:  () => import('@/pages/merchant.vue'),name:"Merchant", meta:{keepAlive:true}},
    { path: '/Merchant_details/:id', name:"Merchant_details", component:  () => import('@/pages/merchant_details.vue')},
    { path: '/User', component:  () => import('@/pages/user.vue'), name:"User", meta:{keepAlive:true}},
    { path: '/DiscountDetails/:id', name:"DiscountDetails", component:  () => import('@/pages/user_discount_details.vue')},
    { path:'/:catchAll(.*)', redirect:"/MerchantMap" }
]
const router = VueRouter.createRouter({
    mode:'hash',
    history: VueRouter.createWebHashHistory('/'),
    // history:VueRouter.createWebHistory('/~'),
    routes, // `routes: routes` 的缩写
})

router.beforeEach(function (to, from, next) {
    let shareInfo = getShareInfo();
	//处理成功结果
    let authRedirectUrl = document.location.protocol+"//"+document.location.hostname+"/index.html?"+(Math.floor(Math.random()*(999-100+1))+100)+"#"+to.path;
    setAuthRedirectUrl(authRedirectUrl);
	wx.ready(function(){
		shareInfo.link = document.location.protocol+"//"+document.location.hostname+"/index.html?"+(Math.floor(Math.random()*(999-100+1))+100)+"#"+to.path; // 分享链接
		//分享到朋友圈
		wx.onMenuShareTimeline(shareInfo);

		//分享给朋友
		wx.onMenuShareAppMessage(shareInfo);

        wx.miniProgram.postMessage({
            data: shareInfo
        })
	});
	next();
});
export default router;