<template>
  <div class="page">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/> 
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
    </router-view> 
  </div>
  
  <!-- 首次进入的广告页 -->
  <transition enter-active-class="adver-ani" leave-active-class="adver-ani-leave">
    <div class="page-adver" v-if="adverShow">
        <div class="adver-btn"></div>
    </div>
  </transition>

  <div class="tabber">

      <router-link v-show="!pages.includes(currentPath)"  to="/WelfareClass" class="tabber-item"><svg-icon icon-class="home" />回到首页</router-link>

      <router-link v-show="pages.includes(currentPath)"  to="/WelfareClass" class="tabber-item"><svg-icon icon-class="tableware" />打卡福利</router-link>
      
      <router-link 
      v-show="pages.includes(currentPath)" 
      to="/MerchantMap" class="tabber-item">
        <svg-icon icon-class="address" />探索东区
      </router-link>

      <div v-show="!pages.includes(currentPath)" 
      @click="$router.back()" class="tabber-item">
        <svg-icon icon-class="return" />返回
      </div>

      <router-link 
      v-show="pages.includes(currentPath)" 
      to="/User" class="tabber-item">
      <svg-icon icon-class="user" />个人中心
      </router-link>
      <!-- <a href="https://m.gnete.com/huodong/zsxiaofei/xiaofei.html" class="tabber-item">优惠领取</a> -->
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      pages:['WelfareClass', 'MerchantMap', 'User'],
      adverShow:false,
    }
  },
  mounted(){
    // setTimeout(() => {
    //   this.adverShow = false;
    // }, 2000)
  },
  computed:{
    currentPath(){
      let current = this.$router.currentRoute.value;
      return current.name ? current.name  : (!this.$router.currentRoute.value.redirectedFrom ? "WelfareClass" : this.$router.currentRoute.value.redirectedFrom.name);
    }
  }
}
</script>

<style>
  *{
      padding:0;
      margin: 0;
  }
  img{
    width: 100%;
  }
  div{
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
      -webkit-user-select: none;
      -moz-user-focus: none;
      -moz-user-select: none;
      -webkit-appearance:none;
      outline: none;
      border: none;
  }
  a{
      color: inherit;
      text-decoration: none;
  }
  img{
      -webkit-user-select: none;
      cursor: default;
      pointer-events: none;
  }
  input{
  　　-webkit-appearance:none;
      border: 0;
      caret-color: #FFF;
      outline:none;
  }
  input::placeholder{
    border: none;
    text-shadow: none;
  }
  html{
    width: 100%;
    height: 100%;
  }
  body{
    max-width: 750px;
    min-width: 320px;
    position: relative;
    margin: 0 auto !important;
    -webkit-overflow-scrolling: touch;
    background-color: #f5f5f5;
    height: 100vh;
    
  }
  #app{
    width: 100%;
    height: 100%;
  }
  .page{
    width: 100%;
    overflow: auto;
    padding-bottom: 4.75rem;
    height: calc(100vh - 4.75rem);
  }
  .pinch-zoom-container{
    width: 100%;
  }
  .page-adver{
    width: 37.5rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: calc((100% - 37.5rem) / 2);
    background: url(assets/img/common/page-adver.png) center / cover no-repeat #FF952B;
    z-index: 9999;
  }
  .adver-btn{
    width: 22.05rem;
    height: 6.9rem;
    background: url(./assets/img/common/adver-btn.png) center / cover no-repeat;
    position: absolute;
    left: calc((100% - 22.05rem) / 2);
    bottom: 8rem;
  }
  .tabber{
    width: 37.5rem;
    height: 4.75rem;
    background-color: #FFF;
    color: #FF952B;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    text-align: center;
    z-index: 99;
  }
  .tabber-item{
    flex:1;
    font-size: 1.4rem;
  }
  .tabber-item svg{
    margin-right: .5rem;
  }
  .tabber-item:not(:last-child){
    border-right: 0.05rem solid #FF952B;
  }
  .router-link-active{
    color: #FF6800;
  }
  .app{
    width: 100%;
    padding-bottom: 4.75rem;
    height: calc(100% - 4.75rem);
  }

  .adver-ani{
    animation: adverAni .5s;
  }
  .adver-ani-leave{
    animation: adverAni .5s reverse;
  }
  @keyframes adverAni {
      from{ opacity: 0; }
      to{ opacity: 1; }
  }

  .page-bottom{
    position: fixed;
    right: 1rem;
    bottom: 5rem;
    color: #a4a4a4;
    z-index: 99;
    padding: .2rem .5rem;
    border-radius: .5rem;
  }
</style>
