import wx from "weixin-js-sdk"
import $ from 'jquery'
import { showDialog } from 'vant';
import { Component, createApp } from "vue";
import vanLoading from "@/components/loading.vue";
import * as PIXI from "pixi.js"
import transform  from "./transform";
import alloyFinger  from "./alloy_finger";
// import configObj from '../../../config/index'

/**
 * 加载弹窗
 */
export function Loading(){
    const app = createApp(vanLoading);
    const root = document.createElement('div');
    document.body.appendChild(root);
    const instance = app.mount(root);
    const unmount = () => {
      document.body.removeChild(root);
    }

    return () => {
      instance && unmount();
    }
}

// var ajaxDomain = "";
// if(window.location.host == 'symsdtc.wukesiyi.cn'){
//   ajaxDomain = '//symsdtb.wukesiyi.cn/'; // 生产环境
// }else{
//   // ajaxDomain = "//"+window.location.host+"/"; // 开发环境
//   ajaxDomain = '/';
// }

/*
*   初始化rem数值
*   isLand: 兼容横屏, 默认false
*/ 
export function initRem (win, doc, isLand=false) {
    if (!win.addEventListener) return
    function setFont() {
        var html = document.documentElement
        var k = 750
        if(isLand){
          var w = document.documentElement.clientWidth,
              h = document.documentElement.clientHeight;
          if (w > h) {
            k = 1600
            document.documentElement.setAttribute('class', 'landscape');
          }
          if (w < h) {
            k = 750
            document.documentElement.setAttribute('class', 'portrait');
          }
        }
        var clientWidth = html.clientWidth >= k ? k : html.clientWidth;
        html.style.fontSize = (clientWidth / k) * 20 + 'px'
    }

    setFont()
    doc.addEventListener('DOMContentLoaded', setFont, false)
    win.addEventListener('orientationchange', setFont, false)
    win.addEventListener('resize', setFont, false)
    win.addEventListener('load', setFont, false)
}


/**
* 封装ajax，包括跨域同源策略
* @param requestPath
* @param postData
* @param successCallback
*/
var authRedirectUrl = document.location.protocol+"//"+document.location.hostname+"/index.html?"+(Math.floor(Math.random()*(999-100+1))+100)+"#/Home"; // 授权后回调地址

export const setAuthRedirectUrl = (value) => {
    authRedirectUrl = value;
    return true;
}

export const wksy_request = (requestPath, postData, successCallback, showLoading) => {
  if(showLoading){
    var closeLoading = Loading();
  }
  postData['authRedirectUrl'] = authRedirectUrl;
  $.ajax({
      url: process.env.VUE_APP_BASE_URL + requestPath,
      type: 'POST',
      crossDomain:true,
      data: postData,
      timeout: 10000,
      xhrFields: {
          withCredentials: true // 发送Ajax时，Request header中会带上 Cookie 信息。 同源跨域携带cookie协议
      },
      success: function(res){
          if(res.loginStatus){
              location.replace(res.loginUrl);
          }else if(typeof successCallback == 'function'){
              successCallback(res);
          }
      },
      complete: function( xhr, data ){
        if(showLoading){
          // layer.close(loading);
          closeLoading();
        }
        if(xhr.status != 200){
            if (xhr.statusText == 'timeout') {
                xhr.abort(); //取消请求
                showDialog({ message: '网络有点问题，请稍后重试...' });
            }else{
                // 未知错误
                xhr.abort(); //取消请求
                showDialog({ message: '网络有点问题，请稍后重试...' });
            }
        }
      }
  });
}
/**
* 加载分享信息
*/
var shareInfo = {
    link: document.location.protocol+"//"+document.location.hostname+"/index.html?"+(Math.floor(Math.random()*(999-100+1))+100)+"#/Home", // 分享链接
};
wksy_request("Api/Wechat/share_info", { url: window.location.href }, function( res ){
    if(!res.status) return;
    wx.config(res.data.JsConfig);

    //处理成功结果
    wx.ready(function(){
      if(res.data.shareInfo){
        shareInfo = res.data.shareInfo;
      }
      //分享到朋友圈
      wx.onMenuShareTimeline(shareInfo);

      //分享给朋友
      wx.onMenuShareAppMessage(shareInfo);

        wx.miniProgram.postMessage({
            data: shareInfo
        })
    });

    //如果微信配置错误会提示
    wx.error(function(err) {
      console.log("JsConfig出错了：" + err.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    });
})

export function getShareInfo(){
    return shareInfo;
}

var qiniuUploadParams = {
    token: null,
    tokenEp: null
};
const get_qiniu_token = () => {
    var now = new Date().getTime() / 1000;
    if(!qiniuUploadParams.token || !qiniuUploadParams.tokenEp || qiniuUploadParams.tokenEp <= now){
        $.ajax({
            async: false,
            type: 'post',
            url: process.env.VUE_APP_BASE_URL + '/Api/Upload/get_qiniu_upload_token', // 七牛上传token
            success: function(res){
                if (res.uptoken) {
                    qiniuUploadParams.token = res.uptoken;
                    qiniuUploadParams.tokenEp = (now + 3500);
                    return;
                }
            }
        });
    }
    return;
}

export const qiniu_upload = (fileData, fileName, successCallback, showLoading) => {
    var formData = new FormData();
    get_qiniu_token();

    fileName += new Date().getTime() + Math.ceil(Math.random() * 1000) + '.png';
    let file = new File([fileData], fileName, {type: 'image/png'});
    formData.append('file', file);
    formData.append('key', fileName);
    formData.append('token', qiniuUploadParams.token);

    if(showLoading){
        var closeLoading = Loading();
    }
    $.ajax({
        type: "post",
        url: "//up-z2.qiniup.com/", //用于文件上传的服务器端请求地址
        data: formData,
        processData: false,    // processData和contentType必须指定为false
        contentType: false,
        cache: false,
        success:function(res){
            if(showLoading){
                // layer.close(loading);
                closeLoading();
            }
            if(typeof successCallback == 'function'){
                successCallback(res);
            }else{
                showDialog({ message: '上传成功' });
            }
        }
    });
}

export class Map{
	constructor(){
		transform();
		alloyFinger();

		this.Application = PIXI.Application;  //画布容器
		this.loader = new PIXI.Loader();	//加载插件
		this.resources = this.loader.resources;	//图片资源
    	this.Sprite = PIXI.Sprite;	//元素实例
    	this.Rectangle = PIXI.Rectangle; //图片裁剪，用于雪碧图
    	this.isTap = false;  //元素允许点击

		this.createStage();  
		// this.canvasDrag(); //设置画布拖拽	
		this.zoomMap();
		this.moveMap();

	}

	// 创建舞台
	createStage(callBack){
		this.mapApp = new this.Application({width: 4036, height: 3526});
		this.mapApp.stage.sortableChildren = true;
		$('.map').append(this.mapApp.view);
		if( typeof callBack == 'function')
			callBack();
	}

	/*设置图层层级*/
	setZIndex(index){
		return new PIXI.display.Group(index, true);
	}

	/*
	* 所有图片加载
	*/
	AllImgLoad(callBack){
		// loading.showLoading({
        //     type:1,
        //     tip:"加载中..."
        // });
		var closeLoading = Loading();
		this.loader
		.add('map', require("@/assets/img_v1/map.jpg"))
		.add('archi_1', require("@/assets/img_v1/archi/01.png"))
		.add('archi_2', require("@/assets/img_v1/archi/02.png"))
		.add('archi_3', require("@/assets/img_v1/archi/03.png"))
		.add('archi_4', require("@/assets/img_v1/archi/04.png"))
		.add('archi_5', require("@/assets/img_v1/archi/05.png"))
		.add('archi_6', require("@/assets/img_v1/archi/06.png"))
		.add('archi_7', require("@/assets/img_v1/archi/07.png"))
		.add('archi_8', require("@/assets/img_v1/archi/08.png"))
		.add('cursor', require("@/assets/img_v1/cursor.png"))
		.add('scenic_1', require("@/assets/img_v1/scenic/01.png?v1.2"))
		.add('scenic_1_', require("@/assets/img_v1/scenic/01_.png"))
		.add('scenic_2', require("@/assets/img_v1/scenic/02.png?v1.2"))
		.add('scenic_2_', require("@/assets/img_v1/scenic/02_.png"))
		.add('scenic_3', require("@/assets/img_v1/scenic/03.png?v1.2"))
		.add('scenic_3_', require("@/assets/img_v1/scenic/03_.png"))
		.add('scenic_4', require("@/assets/img_v1/scenic/04.png?v1.2"))
		.add('scenic_4_', require("@/assets/img_v1/scenic/04_.png"))
		.add('scenic_5', require("@/assets/img_v1/scenic/05.png?v1.2"))
		.add('scenic_5_', require("@/assets/img_v1/scenic/05_.png?1.2"))
		.add('decorate_1', require("@/assets/img_v1/decorate/01.png?v1.2"))
		.add('decorate_2', require("@/assets/img_v1/decorate/02.png?v1.2"))
		.add('decorate_3', require("@/assets/img_v1/decorate/03.png?v1.2"))
		.add('decorate_4', require("@/assets/img_v1/decorate/04.png?v1.2"))
		.add('decorate_5', require("@/assets/img_v1/decorate/05.png?v1.2"))
		.add('decorate_6', require("@/assets/img_v1/decorate/06.png?v1.2"))
		.add('decorate_7', require("@/assets/img_v1/decorate/07.png?v1.2"))
		.add('decorate_8', require("@/assets/img_v1/decorate/08.png?v1.2"))
		.add('decorate_9', require("@/assets/img_v1/decorate/09.png?v1.2"))
		.add('decorate_10', require("@/assets/img_v1/decorate/10.png?v1.2"))
		.add('decorate_11', require("@/assets/img_v1/decorate/11.png?v1.2"))
		.add('decorate_12', require("@/assets/img_v1/decorate/12.png?v1.2"))
		.add('decorate_13', require("@/assets/img_v1/decorate/13.png?v1.2"))
		.add('decorate_14', require("@/assets/img_v1/decorate/14.png?v1.2"))
		.add('spr2', require("@/assets/img_v1/spr2.png"))
		.load(() => {
			this.setBgImg(); // 设置背景
			this.createDeconrate();
			this.carAnimate();
			setTimeout(function(){
				// loading.hideLoading();
				closeLoading();
				if(typeof callBack == 'function')
					callBack();			
			},  1500)
		});
	}

	//设置背景
	setBgImg(){
		let sprite = new this.Sprite(
			this.resources["map"].texture
		);
		this.mapApp.stage.addChild(sprite);
	}

	/*
	* 建筑数据
	*/
	archiData(){
		return [
			{width:681, height:675, sprx:686, spry:474, x:863, y:293,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:400, y:382}, zIndex:3},
			{width:686, height:368, sprx:0, spry:0, x:700, y:1034,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:280, y:60},zIndex:10},
			{width:708, height:370, sprx:738, spry:0, x:372, y:1420,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:445, y:180},zIndex:0},
			{width:692, height:538, sprx:2346, spry:0,x:1728, y:591,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:400, y:370},zIndex:0},
			{width:618, height:636, sprx:1446, spry:493, x:1762, y:1185,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:370, y:405},zIndex:0},
			{width:595, height:718, sprx:0, spry:493, x:1777, y:2231,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:237, y:319},zIndex:0},
			{width:753, height:453, sprx:1498, spry:0, x:3189, y:2467,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:150 , y:127},zIndex:0},
			{width:561, height:353, sprx:1498, spry:0, x:2518, y:2443,ani:[{w:151, h:151, x:0, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:349, y:0},{w:151, h:151, x:159, y:0},{w:151, h:151, x:0, y:0}], aniPosition:{x:220 , y:127},zIndex:0}
		]
	}

	/*
	* 创建建筑
	*/
	createArchi(onTap){
		let that = this;
		let list = this.archiData();
		let index = 0;
		for(let i=0; i<list.length; i++){
			// 创建分组
			let animals = new PIXI.Container();
			// 创建分组子项：建筑
			let archiResouce = that.resources[`archi_${i+1}`].texture;
			let archi = new that.Sprite(archiResouce);
			// 添加到分组
			animals.addChild(archi)
			animals.position.set(list[i].x, list[i].y);

			// 分组添加在舞台
			that.mapApp.stage.addChild(animals);
			that.mapApp.renderer.render(that.mapApp.stage);

			animals.interactive = true;
			animals.on('pointerup', (ev) => {
				setTimeout(function(){
					if(typeof onTap == 'function' && that.isTap){
						onTap(i);
					}
				}, 100)
			})
		}
	}

	/* 创建光标动画 */
	createFrame(list) {
		//获取纹理
        let base = this.resources['cursor'].texture;
        //创建纹理数组
        let textureList = [];

        list.forEach(item => {
	        let texture = new PIXI.Texture(base);
	        texture.frame = new PIXI.Rectangle(item.x, item.y, item.w, item.h);
	        textureList.push(texture)
        })

        //创建动画精灵
        let frame = new PIXI.AnimatedSprite(textureList);
      
        //设置动画精灵的速度
        frame.animationSpeed=0.1;
        return frame;
	}

	/*
	* 景点数据
	*/
	scenicData(){
		return [
			{x:1830, y:208, ani:{x:245, y:52}, zIndex:0},
			{x:2315, y:545, ani:{x:447, y:76}, zIndex:1},
			{x:3106, y:465, ani:{x:288, y:360}, zIndex:0},
			{x:2421, y:1466, ani:{x:293, y:400}, anis:[{x:0, y:1615, w:680, h:437},{x:809, y:1615, w:680, h:437},{x:0, y:2083, w:680, h:437},{x:809, y:2083, w:680, h:437}], zIndex:0},
			{x:2550, y:2029, ani:{x:-250, y:150}, zIndex:0},
		]
	}

	/*
	* 创建景点
	*/
	createScenic(onTap){
		let that = this;
		let list = this.scenicData();
		let index = 0;
		for(let i=0; i<list.length; i++){
			// 创建分组
			let animals = new PIXI.Container();
			if(list[i].anis){
				var archi = that.createDeconAni(list[i].anis);
				archi.play();
			}else{
				// 创建分组子项：建筑
				let archiResouce = that.resources[`scenic_${i+1}`].texture;
				var archi = new that.Sprite(archiResouce);
			}
			// 添加到分组
			animals.addChild(archi)
			animals.position.set(list[i].x, list[i].y);

			// 创建分组子项：景点名
			let archiResouce_ = that.resources[`scenic_${i+1}_`].texture;
			let archi_ = new that.Sprite(archiResouce_);

			// 添加到分组
			animals.addChild(archi_)
			archi_.position.set(list[i].ani.x, list[i].ani.y);

			// 分组添加在舞台
			animals.zIndex = list[i].zIndex; 
			that.mapApp.stage.addChild(animals);
			that.mapApp.renderer.render(that.mapApp.stage);

			animals.interactive = true;
			animals.on('pointerup', (ev) => {
				setTimeout(function(){
					if(typeof onTap == 'function' && that.isTap){
						onTap(i);
					}
				}, 100)
			})
		}
	}

	/*
	* 其他装饰图片
	*/
	decorateData(){
		return [
			{x:834, y:389, ani:[{x:0, y:0, w:362, h:264}, {x:372, y:0, w:378, h:264},{x:760, y:0, w:389, h:264}]},
			{x:463, y:624},
			{x:320, y:941},
			{x:83, y:2000, ani:[{x:0, y:332, w:279, h:264}, {x:393, y:332, w:279, h:264}, {x:786, y:332, w:279, h:264}]},
			{x:463, y:2215},
			{x:981, y:2884},
			{x:2484, y:829},
			{x:2872, y:1162},
			{x:3482, y:968, ani:[{x:0, y:698, w:488, h:425},{x:535, y:698, w:496, h:405},{x:1078, y:713, w:524, h:410}]},
			{x:3101, y:1479},
			{x:3390, y:2067},
			{x:3428, y:3063},
			{x:1720, y:1747},
			{x:1000, y:924, zIndex:4},
			{x:1382, y:3071, ani:[{x:41, y:1191, w:290, h:376},{x:413, y:1191, w:290, h:376},{x:827, y:1191, w:290, h:376},{x:1230, y:1191, w:290, h:376},{x:1623, y:1191, w:290, h:376}]},
		]
	}

	/*
	* 创建装饰图
	*/
	createDeconrate(){
		let that = this;
		let list = this.decorateData();
		let index = 0;
		for(let i=0; i<list.length; i++){
			// 创建分组
			let animals = new PIXI.Container();
			if(list[i].ani){
				var archi = that.createDeconAni(list[i].ani);
				archi.play();
			}else{
				// 创建分组子项
				let archiResouce = that.resources[`decorate_${i+1}`].texture;
				var archi = new that.Sprite(archiResouce);
			}	
			// 添加到分组
			animals.addChild(archi)
			animals.position.set(list[i].x, list[i].y);
			animals.zIndex = list[i].zIndex ? list[i].zIndex : 2;

			// 分组添加在舞台
			that.mapApp.stage.addChild(animals);
			that.mapApp.renderer.render(that.mapApp.stage);
		}
	}

	/* 创建装饰动画 */
	createDeconAni(ani){
		//获取纹理
        let base = this.resources['spr2'].texture;
        //创建纹理数组
        let textureList = [];

        ani.forEach(item => {
	        let texture = new PIXI.Texture(base);
	        texture.frame = new PIXI.Rectangle(item.x, item.y, item.w, item.h);
	        textureList.push(texture)
        })

        //创建动画精灵
        let frame = new PIXI.AnimatedSprite(textureList);
      
        //设置动画精灵的速度
        frame.animationSpeed=0.1;
        return frame;
	}

	/*
	* 地图拖拽
	*/
	canvasDrag(){
		let dom = this.mapApp.view;
		let minScale = $('.map').height() / this.mapApp.renderer.view.height;
		let scale = minScale * 1; //默认放大2倍
		let scaleStep = 0.001; //缩放步进器
		let scaleMove = {left:0, top:0};
		let move = { //偏移量
			left:0, 
			top:0
		};
		$(dom).css({
			transformOrigin:`0% 0%`,
			transform:`scale(${scale})`,
			position:'absolute'
		})

		let startOffsetX = 0,
			startOffsetY = 0,
			moveX = {max:0, min:0},
			moveY = {max:0, min:0},
			isDrag = true;
		
		function onDrag(ev){
			if(!isDrag){
				return ;
			}
			move.left = ev.changedTouches[0].clientX - startOffsetX;
			move.top = ev.changedTouches[0].clientY - startOffsetY;
			move.left = move.left > moveX.max ? moveX.max : move.left;
			move.left = move.left < moveX.min ? moveX.min : move.left;
			move.top = move.top > moveY.max ? moveY.max : move.top;
			move.top = move.top < moveY.min ? moveY.min : move.top;
		    $(dom).css({
		    	transform:`translate(${move.left+'px'}, ${move.top+'px'}) scale(${scale})`
		    })
		    ev.preventDefault();
	    }
	    function beforeDrag(ev){ 
			console.log('ssssssssssss')
			startOffsetX = ev.changedTouches[0].globalX * scale; 
			startOffsetY = ev.changedTouches[0].globalY * scale;
			moveX = {
				min:$('.map').width() - ev.target.clientWidth * scale,
				max:0
			}
			moveY = {
				min:$('.map').height() - ev.target.clientHeight * scale,
				max:0
			}
			isDrag = ev.targetTouches.length > 2 ? false : true;
	    }

		// dom.addEventListener("mousedown",beforeDrag,true); 
		// dom.addEventListener("mousemove",onDrag,false);
		dom.addEventListener("touchstart",beforeDrag,true); 
		dom.addEventListener("touchmove",onDrag,false,{passive: false});
	}

	/* 地图缩放 */
	zoomMap(){
		var that = this;
		that.pinchImg = this.mapApp.view;
		Transform(that.pinchImg);
		that.scaleSection = {
			max:0.8,
			min:0.3,
		}
		that.initScale = that.scaleSection.min;

		// 设置地图有效移动距离
		that.moveDistance = that.initMoveDistance(that.initScale);

		// 设置地图缩放
		that.pinchImg.scaleX = that.pinchImg.scaleY = that.initScale;

		// 居中
		that.initMapCenter();

		// 窗口改变更新地图
		that.resizeMap();

		/*new AlloyFinger(that.pinchImg, {
		    multipointStart: function () {
		        that.initScale = that.pinchImg.scaleX;
		    	that.isTap = false;
		    },
		    pinch: function (evt) {
		    	that.isTap = false;
		    	// let scale = that.initScale + that.stupScale;
		    	let scale = that.initScale * evt.zoom;
		    	if(scale < that.scaleSection.min){
		    		scale = that.scaleSection.min;
		    	}else if(scale > that.scaleSection.max){
		    		scale = that.scaleSection.max;
		    	}
		    	that.initScale = scale;
		        that.pinchImg.scaleX = that.pinchImg.scaleY = that.initScale;
		        that.initMoveDistance();
				let moveX = that.pinchImg.translateX;
		    	let moveY = that.pinchImg.translateY;
		    	let resMove = that.checkMove(moveX, moveY);
		        that.pinchImg.translateX = resMove.moveX;
		        that.pinchImg.translateY = resMove.moveY;
		    }
		});*/
	}

	/* 移动地图 */
	moveMap(){
		let that = this;
		new AlloyFinger(that.pinchImg, {
			tap:function(){
		    	that.isTap = true;
            },
		    pressMove:function(evt){
		    	that.isTap = false;
		    	if(evt.deltaX < 0.2 && evt.deltaX > -0.2 && evt.deltaY < 0.2 && evt.deltaY > -0.2){
		    		that.isTap = true;
		    	}
		    	let moveX = that.pinchImg.translateX + evt.deltaX;
		    	let moveY = that.pinchImg.translateY + evt.deltaY;
		    	
		    	let resMove = that.checkMove(moveX, moveY);
		        that.pinchImg.translateX = resMove.moveX;
		        that.pinchImg.translateY = resMove.moveY;

		        evt.preventDefault();
		    }
		});
	}

	/* 初始化地图有效移动距离 */
	initMoveDistance(initScale){
		let moveDistance = {
			min:{
				x: -((1 - initScale) * 4036 / 2) - (initScale * 4036 - $('.map').width()),
				y: -((1 - initScale) * 3526 / 2) - (initScale * 3526 - $('.map').height()),
			},
			max:{
				x: -((1 - initScale) * 4036 / 2),
				y: -((1 - initScale) * 3526 / 2)
			}
		}
		return moveDistance;
	}

	/* 初始化地图居中显示 */
	initMapCenter(){
		let that = this;
		// that.pinchImg.translateX = -((1 - that.initScale) * 4036 / 2) - ((that.initScale * 4036 - $('.map').width()) / 2 ) - 90;
		// that.pinchImg.translateY = -((1 - that.initScale) * 3526 / 2) - ((that.initScale * 3526 - $('.map').height()) / 2 );
		that.pinchImg.translateX = -((1 - that.initScale) * 4036 / 2) - ((that.initScale * 4036 - $('.map').width()) / 4 ) - 90;
		that.pinchImg.translateY = 0;
	}

	/* 检测移动距离是否有效 */
	checkMove(moveX, moveY){
		let that = this;
		if(moveX > that.moveDistance.max.x){
    		moveX = that.moveDistance.max.x;
    	}else if(moveX < that.moveDistance.min.x){
    		moveX = that.moveDistance.min.x;
    	}
    	if(moveY > that.moveDistance.max.y){
    		moveY = that.moveDistance.max.y
    	}else if(moveY < that.moveDistance.min.y){
    		moveY = that.moveDistance.min.y;
    	}

    	return {moveX:moveX, moveY:moveY}
	}

	/* 窗口改变更新地图 */
	resizeMap(){
		let that = this;
		that.moveDistance = that.initMoveDistance(that.initScale);
		let resMove = that.checkMove(that.pinchImg.translateX, that.pinchImg.translateY);
		that.pinchImg.translateX = resMove.moveX;
		that.pinchImg.translateY = resMove.moveY;
		// window.addEventListener('resize', function(){
		// 	that.moveDistance = that.initMoveDistance(that.initScale);
		// 	let resMove = that.checkMove(that.pinchImg.translateX, that.pinchImg.translateY);
		// 	that.pinchImg.translateX = resMove.moveX;
		// 	that.pinchImg.translateY = resMove.moveY;
		// }, false)
	}

	/* 路径关键点 */
	pageData(){
		return [
			{start:[1540, -100], end:[1707, 507], t:0.11, r:-0.2},
			// {start:[1590, 0], end:[1707, 507], t:0.11, r:-0.2},
			{start:[1707, 507], end:[1441, 1035], t:0.3, r:0.2},
			{start:[1441, 1035], end:[1416, 1213], t:-0.53, p:30, r:-0.5},
			{start:[1416, 1213], end:[1561, 1816], t:0.35, r:0.6},
			{start:[1561, 1816], end:[1548, 2189], t:-0.15, p:50, r:-0.2},
			{start:[1548, 2189], end:[1494, 2710], t:0.2, p:80, r:0.2},
			{start:[1494, 2710], end:[1616, 3009], t:-0.5, p:50, r:-0.4},
			{start:[1616, 3009], end:[2018, 3095], t:-0.1, p:50, r:-0.5},
			{start:[2018, 3095], end:[2903, 3344], t:0.12, r:-0.4},
			{start:[2903, 3344], end:[3500, 3602], t:-0.05, r:-0.4},
		]
	}

	/**
	* 绘制一条曲线路径
	* @param  {Array<number>} start 起点
	* @param  {Array<number>} end 终点
	* @param  {number} curveness 曲度(0-1)
	* @param  {number} curveness 曲度(0-1)
	* return {Array} 返回路径上的点
	*/
	drawCurvePath(start, end, curveness, rotation, precise) {
		let that = this;

		let line = new PIXI.Graphics();
		line.lineStyle(6, 0xFF3300, 1);
		line.position.set(0,0)

	    var cp = [
	         ( start[ 0 ] + end[ 0 ] ) / 2 - ( start[ 1 ] - end[ 1 ] ) * curveness,
	         ( start[ 1 ] + end[ 1 ] ) / 2 - ( end[ 0 ] - start[ 0 ] ) * curveness
	    ];
		line.moveTo(start[0], start[1]);
	    var data = [];
	    let stup = 100 / precise / 100;
	    for ( var t = 0; t <= 1; t += stup) {
	        var x = that.quadraticBezier( start[ 0 ], cp[ 0 ], end[ 0 ], t );
	        var y = that.quadraticBezier( start[ 1 ], cp[ 1 ], end[ 1 ], t );
	        var r = rotation / precise;
			// line.lineTo(x,y);
	        data.push({x:x,y:y,r:r});
	    }
		line.zIndex = 100;
		that.mapApp.stage.addChild(line);
	    return data;
	}

	// 二次贝赛尔曲线方程
	quadraticBezier( p0, p1, p2, t) {
		var k = 1 - t;
		return k * k * p0 + 2 * ( 1 - t ) * t * p1 + t * t * p2;    
	}

	quadraticNBezier(list) {
		let precision = 1000; //返回一千个点
		let dimersion = 2; //二位数组
        let result = [];
        //计算坐标点
        for (let i = 0; i < precision; i++) {
            let t = i / precision;
            for (let j = 0; j < dimersion; j++) {
                let temp = 0;
                for (let k = 0; k < list.length; k++) {
                    temp += Math.pow(1 - t, list.length - k - 1) * list[k][j] * Math.pow(t, k) * list[k+1];
                }
                result[i][j] = temp;
            }
        }
        return result;
	}

	/* 车子动画 */
	carAnimate(){
		let that = this;
		// 获取关键点
		let data = that.pageData();

		// 获取所有点
		let allDot = [];
		data.forEach(item => {
			let currenDot = that.drawCurvePath(item.start, item.end, item.t, item.r, item.p ? item.p : 100);
			currenDot.splice(0, 1)
			allDot.push.apply(allDot, currenDot);
		})
		//获取纹理
        let base = this.resources['spr2'].texture;

        // 创建车
        let carWH = {w:117, h:205}
		let carRectangle = new PIXI.Rectangle(93, 2622, carWH.w, carWH.h);
		base.frame = carRectangle
		that.car = new that.Sprite(base);
		that.car.anchor.set(0.5, 0.5);
		// that.car.position.set(1590, 0);
		// that.car.rotation = -0.3;
		that.mapApp.stage.addChild(that.car);

		let index = 0;
		(function run(){
			if(index >= allDot.length){
				setTimeout(function(){
					index = 0;
					run();
				}, 2000)
				return ;
			}

			if(index <= allDot.length-2){
				let rotation = Math.atan((allDot[index].x - allDot[index+1].x) / (allDot[index].y - allDot[index+1].y));
				that.car.rotation = -rotation;
			}
			that.car.position.set(allDot[index].x, allDot[index].y);
			index++;
			requestAnimationFrame(run);
		})()
	}

	carset(x,y,r){
		this.car.position.set(x, y);
		this.car.rotation = r;
	}
}

// wksy_request("Api/Test/login_user", { id: 2001 }, function( res ){
//     console.log('success')
// })